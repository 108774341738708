.content-slide {
    position: relative;
}
.content-slide__content {
    text-align: center;
}
@media screen and (min-width: 768px) {
    .content-slide__content {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        text-align: left;
    }
}