:root {
    --navbar-height-mobile: 60px;
    /*--navbar-height-affix: 50px;*/
    /*--navbar-border-width: 3px;*/
}

@media screen and (max-width: 767px) {
    /*todo importants*/
    .page-wrapper {
        /*padding-top: var(--navbar-height-mobile);*/
    }
    .navbar-main .navbar-header {
        height: var(--navbar-height-mobile);
    }
    .page-wrapper:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        background: #000;
        transition: all 300ms,visibility .1s 500ms;
        z-index: 1000;
    }
    .snapjs-right .page-wrapper:after {
        transition: all 1000ms,visibility .1s;
        opacity: .6;
        visibility: visible;
    }

    /*Navbar*/
    .navbar-main.navbar-main {
        /*override affix*/
        /*position: absolute;*/
    }
    .navbar-nav-main {
        margin: 0;
    }

    /*Toggle Button*/
    .navbar-toggle,
    .navbar-main .navbar-toggle {
        margin: 0 -10px 0 0;
        padding: 13px 15px;
        color: #c3cbd4;
        font-size: 27px;
        line-height: 1;
    }
    .navbar-main .navbar-toggle {
        margin: 0;
    }
    .navbar-main .navbar-toggle .icon-bar {
        width: 30px;
        background: #c3cbd4;
        height: 6px;
        border-radius: 5px;
    }
    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 6px;
    }

    /*Off-canvas*/
    .mobile-nav {
        position: absolute;
        top: 0;
        right: 0;
        width: 295px;
        padding: 15px;
        padding-top: 0;
        z-index: 0;
        background: #ffffff;
    }
    .navbar-nav-main>li>a {
        display: block;
        position: relative;
        text-transform: uppercase;
        border-bottom: 1px solid #acacac;
        padding: 20px 15px 18px 20px;
        font-size: 16px;
    }
    .navbar-nav-main>li:first-child {
        border-top: 1px solid #acacac;
    }
    .mobile-nav__dropdown-icon {
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
        text-align:center;
        padding: 6px 0;
        width:40px;
        height:40px;
        font-size: 30px;
        transform: translateZ(0);
        transition: transform 150ms ease;
    }
    .is-open>a>.mobile-nav__dropdown-icon {
        transform: translateZ(0) rotate(180deg);
    }
    .navbar-nav-main ul>li .mobile-nav__dropdown-icon {
        top: 0;
    }
    .navbar-nav-main ul>li.is-open>ul {
        display: block;
    }
    /*Dropdowns*/
    .navbar-nav-main>li.open>a {
        /*color: var(--color-secondary);*/
        /*text-decoration: underline;*/
        background: var(--color-info);
    }
    .main-nav__dropdown .container {
        padding: 0;
    }
    /*todo*/
    .main-nav__dropdown .pull-right {
        float: none !important;
    }
    .main-nav__dropdown ul > li > a {
        display: block;
        position: relative;
        padding: 10px 8px;
    }
    .main-nav__dropdown ul {
        padding-left: 10px;
    }
    .main-nav__dropdown ul ul {
        display: none;
    }
    .main-nav__dropdown li.active > ul,
    .main-nav__dropdown li.open > ul {
        display: block;
    }
    .main-nav__dropdown ul ul ul ul>li {
        font-size: 14px;
    }
    .main-nav__mobile-search>.btn {
        font-size: 30px;
        line-height: 1;
        margin-top: -24px;
        padding-right: 0;
    }
    /*.nav-block__title {*/
        /*font-family: var(--font-bold-family);*/
        /*font-size: 16px;*/
        /*padding: 10px 20px;*/
        /*margin: 0;*/
    /*}*/
}

@media screen and (min-width: 768px) {
    .main-nav__dropdown .main-nav__dropdown {
        display: none !important;
    }
}