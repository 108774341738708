.pagination {
    font-size: 1em;
}
.pagination > li > a,
.pagination > li > span {
    background: inherit;
    color: inherit;
    padding: 4px 12px;
    border-radius: 0;
    border: none;
}
/*todo first and last icon */
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: 0;
}
.pagination li > a > span.icon-arrow-right,
.pagination li > a > span.icon-arrow-left{
    margin-top: 3px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background: transparent;
    color: var(--color-secondary);
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    background-color: var(--color-primary);
    color: var(--color-primary-comp);
}
.paging-row {
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 2;
}
.paging-row .pagination {
    margin: 0;
}
