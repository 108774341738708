:root {
    --img-poi-bg-color: rgba(255,255,255,.8);
    --img-poi-bg-color-fallback: #fff;
    --img-poi-bg-active: var(--color-secondary);
    --img-poi-text-color: #e6007e;
    --img-poi-text-color-active: #fff;
}
.img-poi {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: var(--img-poi-bg-active);
    color: #ffffff;
    box-shadow:  0 0 0 5px rgba(255, 255, 255, 0.8);
    transform: translate(-50%,-100%);
}
.img-poi__content {
    display: block;
    position: absolute;
    bottom: 42px;
    left: auto;
    right: -13px;
    background: var(--img-poi-bg-color-fallback);
    background: var(--img-poi-bg-color);
    color: var(--img-poi-text-color);
    white-space: nowrap;
    padding: 8px 15px;
    border-radius: 4px;
    border-bottom-right-radius: 0;
}
.img-poi__content:after {
    content: '\E03C';
    display: inline-block;
    font-family: iconfont;
    position: absolute;
    top: 100%;
    margin-top: -3px;
    left: auto;
    right: 1px;
    font-size: 17px;
    color: var(--img-poi-bg-color-fallback);
    color: var(--img-poi-bg-color);
}
/*hover*/
a.img-poi:hover {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
}
a.img-poi:hover>.img-poi__content,
a.img-poi:focus>.img-poi__content{
    background: var(--img-poi-bg-active);
    color: var(--img-poi-text-color-active);
}
a.img-poi:hover>.img-poi__content:after,
a.img-poi:focus>.img-poi__content:after {
    color: var(--img-poi-bg-active);
}
a.img-poi:hover, a.img-poi:focus {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
    background: var(--img-poi-bg-color);
}
.img-poi:focus { outline: none; }