.teaser.video-teaser {
    cursor: pointer;
}
.teaser.video-teaser .holder {
    position: relative;
}
.teaser.video-teaser .holder .asset:before {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '\E029';
    display: inline-block;
    color: white;
    background: var(--color-primary);
    font-family: iconfont;
    z-index: 18;
    border-radius: 50%;
    font-size: 1.7em;
    width: 1.7em;
    height: 1.7em;
    transform: translateX(-50%) translateY(-50%);
    line-height: 1.7em;
    text-align: center;
    padding-top: 0.05em;
    padding-left: 0.1em;
}

.webcam-play-button{
    position: absolute;
    color: white;
    background: var(--color-primary);
    font-family: iconfont;
    border-radius: 50%;
    font-size: 1.7em;
    width: 1.7em;
    height: 1.7em;
    transform: translate(-50%, -50%);
    line-height: 1.7em;
    text-align: center;
    padding-top: 0.05em;
    padding-left: 0.15em;
    left: 50%;
    top: 50%;
}
.webcam-play-button:before {
    content: '\E029';
}

.webcam-play-button{
    display: block;
}

.teaser.video-teaser .holder .js-video-teaser {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
