.weather-icon {
    height: 60px;
}
.weather-icon-sm {
    height: 55px;
}
.text-winter {
    color: #75ccf5;
    vertical-align: text-bottom;
}

@media screen and (min-width: 767px){
    .weather-icon {
        background-position: center;
    }
    .weather-icon {
        height: 100px;
    }
}