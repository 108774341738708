.panel {
    border-radius: 0;
    border: none;
    box-shadow: none;
}
.panel-primary>.panel-heading {
    background: var(--color-primary);
    border: none;
    border-radius: 0;
}
.panel-body {
    border: 1px solid var(--color-info-dark);
}
@media screen and (max-width: 767px) {
    .weather-panel .col.col-xs-6.text-right {
        text-align: left;
    }
}