.hover-zoom {
    position: relative;
    overflow:hidden;
    transform: translateZ(0);
}
.hover-zoom__overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    opacity: 0;
    will-change: opacity;
    transition: opacity 140ms ease;
}
.hover-zoom__overlay:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: rgba(0,0,0,0.35);
}
.hover-zoom__overlay>.icon {
    position: absolute;
    z-index: 1;
    color: #ffffff;
    font-size: 46px;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -23px;
    transform: translateZ(0);
}

@media screen and (min-width: 768px) {
    /*Hover*/
    .hover-zoom__target:hover .hover-zoom__overlay,
    .hover-zoom__target:active .hover-zoom__overlay,
    .hover-zoom__target:focus .hover-zoom__overlay,
    /*todo remove isclickable from css and html templates*/
    .isClickable:hover .hover-zoom__overlay,
    .js-lightbox:hover .hover-zoom__overlay,
    .hover-zoom:hover .hover-zoom__overlay,
    .hover-zoom:active .hover-zoom__overlay,
    .hover-zoom:focus .hover-zoom__overlay {
        opacity: 1;
    }
}