.img-slide {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-top: calc(330*100%/450);
}
.img-slide__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: auto;
}
.img-slide__content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}
.img-slide__content--bottom {
    top: auto;
    bottom: 0;
}
.img-slide__content--full-height {
    top: 0;
    bottom: 0;
}
.img-slide__hl {
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: 0 0 15px rgba(0,0,0,.5);
    line-height: 1;
}
.img-slide__hl>strong {
    font-size: 35px;
    display: block;
}
.img-slide__btn {
    position: relative;
    z-index: 5;
}

@media screen and (min-width: 768px) {
    .img-slide {
        padding-top: 29.7619%;
    }
    /* animation*/
    .slick-active .img-full-width,
    .img-slide > picture > img{
        -webkit-animation: animationScale 4s ease-in-out;
        animation: animationScale 4s ease-in-out;
    }
    @-webkit-keyframes animationScale {
        0% {
            -webkit-transform: scale(1.1);
        }
        100% {
            -webkit-transform: scale(1.0);
        }
    }

    @keyframes animationScale {
        0% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1.0);
        }
    }
    .img-slide__dots {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1240px;
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        margin: auto;
        margin-bottom: 8%;
    }
    .img-slide--big {
        padding-top: 41.66666%;
    }
    .img-slide__hl {
        font-size: 42px;
    }
    .img-slide__hl>strong {
        font-size: 60px;
    }
    #header-slider:after {
        width:425px;
        height:174px;
        background-size:426px 175px;
        bottom:0;
    }
    .img-slide--content-overlap {
        padding-top: calc(900 / 1680 * 100%);
        margin-bottom: calc(460 / 1680 * -100%);
    }
}

#header-slider:after {
    content:'';
    position: absolute;
    right:0;
    bottom:25px;
    width:213px;
    height:86px;
    pointer-events: none;
    background: url(/static/img/sdt-overlay.png) 0 0 no-repeat;
    background-size:213px 87px;
}
@media screen and (min-width: 1200px) {
    .img-slide__hl {
        font-size: 55px;
    }
    .img-slide__hl>strong {
        font-size: 70px;
    }
}

@media screen and (max-width: 767px) {
    .img-slide--xs-stacked {
        height: auto;
        padding-top: 0;
    }
    .img-slide .slick-dots {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }
    .img-slide--xs-stacked .img-slide__background {
        position: relative;
    }
}

.img-slide .slick-slide {
    position: relative;
}

.js-headerSlider:not(.slick-slider) .js-headerSlider__item,
.js-headerSlider:not(.slick-slider) .img-slide__content{
    display: none;
}