/**:focus {*/
    /*outline: 4px solid gold !important;*/
/*}*/

.show-accessibility-helper main {
    position: relative;
    outline:2px solid green;
    border: 2px solid green;
}
.show-accessibility-helper main:after {
    content: 'main';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: green;
}
.show-accessibility-helper section {
    position: relative;
    outline:2px solid yellow;
    border: 2px solid yellow;
}
.show-accessibility-helper section:after {
    content: 'section';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: yellow;
}
.show-accessibility-helper address {
    position: relative;
    outline:2px solid #ffff33;
    border: 2px solid #ffff33;
}
.show-accessibility-helper address:after {
    content: 'address';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: #ffff33;
}
.show-accessibility-helper aside {
    position: relative;
    outline:2px solid darkslategrey;
    border: 2px solid darkslategrey;
}
.show-accessibility-helper aside:after {
    content: 'aside';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: darkslategrey;
}
.show-accessibility-helper .js-toc__title {
    outline:2px solid darkblue;
    border: 2px solid darkblue;
}
.show-accessibility-helper .js-toc__title.sr-only {
    position: static;
    height: auto;
    width: auto;
    clip: auto;
    margin: 0;
}
.show-accessibility-helper .sr-only-focusable,
.show-accessibility-helper .sr-only-focusable {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
.show-accessibility-helper nav:not(.affix-top) {
    position: relative;
    outline:2px solid lightcoral;
    border: 2px solid lightcoral;
}
.show-accessibility-helper nav:after {
    content: 'nav';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: lightcoral;
}

.show-accessibility-helper h1:before {
    content: '<h1> ';
    color: #24b377;
}
.show-accessibility-helper h2:before {
    content: '<h2> ';
    color: #24b377;
}
.show-accessibility-helper h3:before {
    content: '<h3> ';
    color: #24b377;
}
.show-accessibility-helper h4:before {
    content: '<h4> ';
    color: #24b377;
}
.show-accessibility-helper h5:before {
    content: '<h5> ';
    color: #24b377;
}
.show-accessibility-helper h6:before {
    content: '<h6> ';
    color: #24b377;
}
.show-accessibility-helper h1:after {
    content: ' </h1>';
    color: #24b377;
}
.show-accessibility-helper h2:after {
    content: ' </h2>';
    color: #24b377;
}
.show-accessibility-helper h3:after {
    content: ' </h3>';
    color: #24b377;
}
.show-accessibility-helper h4:after {
    content: ' </h4>';
    color: #24b377;
}
.show-accessibility-helper h5:after {
    content: ' </h5>';
    color: #24b377;
}
.show-accessibility-helper h6:after {
    content: ' </h6>';
    color: #24b377;
}
.show-accessibility-helper a:before {
    content: '/';
    color: #0021b3;
}