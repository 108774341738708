:root {
    --nav-tree--active-color: var(--color-secondary);
}
.nav-tree__hl {
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.nav-tree>ul {
    padding-left: 0;
    font-size: calc(18em / 16);
}
.nav-tree ul>li {
    position: relative;
    margin-bottom: calc(12em / 18);
    text-indent: 0;
    padding-left:15px;
}
.nav-tree ul ul li {
    padding-left:0;
}
.nav-tree ul ul {
    margin-top: 1em;
    padding-left: 16px;
    font-size: 15px
}
.nav-tree ul ul ul {
    font-size: 14px
}

.nav-tree a:hover,
.nav-tree .active:before,
.nav-tree .active>a {
    color: var(--nav-tree--active-color);
}

.nav-tree .list-arrows li:before{
    position: absolute;
    left:-5px;
    top: 3px;
}
.nav-tree ul.list-arrows ul li:before{
    left:-15px;
}

.sidebar .icon{
    position: relative;
    /*top: 2px;*/
}