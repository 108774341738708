.legend.box .list-inline li {
    margin-bottom: 0px;
    line-height: 24px;
}
.legend.box .list-inline.pisten li {
    margin-top: 10px;
}
.btn-group .btn-grey {
    background: var(--color-info);
    color: var(--color-primary);
    border-right: 1px solid var(--color-primary-comp);
}
.btn-group .btn-grey:hover,
.btn-group .btn-grey:focus {
    background: var(--color-primary);
    color: var(--color-primary-comp);
}
.btn-group .btn-primary.btn-grey.active {
    background: var(--color-primary);
    color: var(--color-primary-comp);
}


@media screen and (max-width: 767px) {
    .btn-group-justified>.btn-group--xs-block {
        display: block;
        width: 100%;
    }
}