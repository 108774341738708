.teaser-slider {
    position: relative;
    max-width: 280px;
    margin: auto;
    overflow: hidden;
}
.teaser-slider .slick-btn--prev,
.teaser-slider .slick-btn--next {
    display: block;
    position: absolute;
    top: 24%;
    left: 0;
    width: 20px;
    height: 40px;
    background: #ffffff;
    border: none;
    opacity: .85;
    transistion: opacity 130ms ease;
    will-change: opacity;
    border-radius: 0 100px 100px 0;
    text-align: center;
    font-size: 20px;
    color: var(--color-primary);
}
.teaser-slider .slick-btn--prev>.icon,
.teaser-slider .slick-btn--next>.icon {
    line-height: 38px;
}
.teaser-slider .slick-btn--prev>.icon:before,
.teaser-slider .slick-btn--next>.icon:before {
    display: inline-block;
    position: relative;
    left: 0px;
}
.teaser-slider .slick-btn--next {
    left: auto;
    right: 0;
    border-radius: 100px 0 0 100px;
}
.teaser-slider .slick-btn--prev:hover,
.teaser-slider .slick-btn--next:hover {
    opacity: 1;
}