.slide-teaser {
    font-size: 20px;
}


.slide-teaser__content {
    padding: 15px;
}
.slide-teaser.bottom.slide-teaser--big:before {
    display: none !important;
}
@media screen and (min-width: 768px) {
    .slide-teaser.bottom .slide-teaser__content-wrapper {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
        transform: translateY(-90%);
        display: block;
    }

    .slide-teaser.bottom .slide-teaser__content {
        bottom: 30px;
        position: relative;
        transform: none;
    }
    .slide-teaser.bottom .slide-teaser__content-wrapper:before {
        content: '';
        display: inline-block;
        position: absolute;
        background: url(/static/img/overlays/bg-faecher-blue.png);
        right: 0;
        left: 0;
        width: 100%;
        height: 48px;
        top: 0;
        background-size: cover;
        top: 0;
        transform: translateY(-100%);
    }
    .slide-teaser__content-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 490px;
        padding: 30px 40px 30px 0;
    }
    .slide-teaser__content-wrapper:after {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        background: url(/static/img/overlays/bg-slide-teaser-small.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 106px;
    }

    .slide-teaser--big .slide-teaser__content-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        width: 560px;
        max-width: none;
        padding: 0;
        background: rgba(0, 159, 227, 0.9);
    }
    .slide-teaser--big .slide-teaser__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 20px 10px 10px;
    }
    .slide-teaser--big .slide-teaser__content-wrapper:after {
        background-image: url(/static/img/overlays/bg-slide-teaser-right.png);
        width: 138px;
    }
}
@media screen and (min-width: 992px) {
    .slide-teaser.bottom .slide-teaser__content-wrapper:before {
        height: 68px;
    }
}
@media screen and (min-width: 1200px) {
    .slide-teaser.bottom .slide-teaser__content-wrapper:before {
        height: 88px;
    }
    .slide-teaser--big .slide-teaser__content-wrapper {
        width: 530px;
    }
    .slide-teaser--big .slide-teaser__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 40px;

    }

    .slide-teaser--big:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 82px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(/static/img/overlays/bg-slide-teaser-left.png);
        z-index: 1;
    }
}