:root {
    --expandable-panel-header-height: 123px;
}
.expandable-panel {
    position: relative;
    height: 50px;
    cursor: pointer;
    margin-top: 15px;
    background: var(--color-primary);
    color: var(--color-primary-comp);
    border-radius: 9999px;
    padding: 0 50px;
}
.expandable-panel__header {
    display: block;
}
.expandable-panel__header-text {
    padding-top: 7px;
    margin-bottom: 0;
}
.expandable-panel__header-text>.sub{
    display: block;
    font-size: 1rem;
}
.expandable-panel__icon {
    display: inline-block;
    height: 46px;
    width: 46px;
    line-height: 1;
    background: var(--color-primary);
    border-radius: 100%;
    color: #ffffff;
    border: 1px solid;
    font-size: 42px;
    position: relative;
}
.expandable-panel__body.weather .text-left {
    text-align: left;
}
.expandable-panel__body .weather-date time {
    font-weight: 600;
    font-size: 16px;
}
.expandable-panel__body.weather > h4 {
    text-align: left;
}
.expandable-panel__icon .icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50.5%);
    color: var(--color-primary-comp);
}
.expandable-panel__icon-right { display: none; }
/*.expandable-panel__header:focus { outline: none; }*/

@media screen and (max-width: 767px) {
    .expandable-panel__icon {
        position: absolute;
        left: 2px;
        top: 2px;
    }
    .expandable-panel { overflow: hidden; }
    .expandable-panel:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 40px;
        background: var(--color-primary-darkened);
        z-index: 1;
        border-bottom-right-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .expandable-panel__icon-right {
        display: block;
        position: absolute;
        top: 3px;
        bottom: 0;
        right: 8px;
        z-index: 2;
        font-size: 28px;
        vertical-align: middle;
    }
    .expandable-panel__icon-right:before {
        vertical-align: middle;
    }
    .info-button-bar .expandable-panel.is-open {
        height:auto;
        border-radius:25px;
        overflow:visible !important;
    }
    .info-button-bar .expandable-panel__mover {
        transform: none !important;
    }
    .info-button-bar .expandable-panel__body {
        padding:10px 0 5px 0;
    }
    .info-button-bar .expandable-panel__body .btn-primary {
        margin:5px 0 !important;
        background-color: var(--color-primary-soft-darkened) !important;
    }
    .info-button-bar .expandable-panel__body .btn__icon:after {
        background-color: var(--color-primary-soft-darkened) !important;
    }
    #weather-teaser-detail .text-muted,
    #weather-teaser-detail .text-primary {
        color:#fff !important;
    }

}

@media screen and (min-width: 768px) {
    .expandable-panel {
        height: var(--expandable-panel-header-height);
        padding: 0;
        background: #ffffff;
        margin-top: 20px;
        z-index: 3;
        transform: none; /*fallback for ie9*/
        transform: translateZ(0);
        border-radius: 0;
        color: inherit;
    }
    .expandable-panel__wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        padding-top: 50px;
    }
    .expandable-panel__wrapper:hover .expandable-panel__icon{
        background: color(var(--color-primary) b(+10%));
    }
    .expandable-panel__header {
        position: relative;
        height: var(--expandable-panel-header-height);
        z-index: 1;
    }
    .expandable-panel__header:before {
        content: '';
        background-color: var(--color-primary);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        transform-origin: top left;
        /*scale down to 2px*/
        transform: scaleY(calc(2/123)); /*fallback for ie9*/
        transform: scaleY(calc(2/123)) translateZ(0);
        transition: transform 200ms ease;
        will-change: tranform;
    }
    .expandable-panel__header-text {
        padding-top: 0;
        margin-top: 20px;
        color: #595959;
    }
    .expandable-panel__header-text>.sub{
        color: #b2b2b2;
    }
    .expandable-panel__icon {
        margin-top: -40px;
        height: 80px;
        width: 80px;
        /*line-height: 79px;*/
        font-size: 75px;
        border-width: 2px;
    }
    .expandable-panel__body {
        display: none;
        padding: 10px;
        background: #ffffff;
        border: 1px solid var(--color-info);
    }
    .expandable-panel__mover {
        transform: none; /*fallback for ie9*/
        transform: translateZ(0);
        transition: transform 300ms ease; /*ATTENTION: JS depends on the duration, if the duration is changed change the expandablePanelDuration in script.js too*/
        will-change: tranform;
    }

    /*is-open*/
    .expandable-panel__header:focus:before,
    .expandable-panel.is-open .expandable-panel__header:before {
        transform: scaleY(1); /*fallback for ie9*/
        transform: scaleY(1) translateZ(0);
    }
    .expandable-panel__header-text {
        transition: color 150ms 150ms ease;
    }
    .expandable-panel__header:focus .expandable-panel__header-text,
    .expandable-panel.is-open .expandable-panel__header-text,
    .expandable-panel.is-open .expandable-panel__header-text>* {
        color: #ffffff !important;
    }
}

@media screen and (min-width: 1200px) {
    .expandable-panel__body {
        padding: 30px;
    }
}
