.navbar-nav-main>li,
.navbar__item {
    position: relative;
}
.main-nav__dropdown {
    display: none;
    font-size: 16px;
    padding: 5px 0;
}
.main-nav__dropdown .container {
    padding: 0;
}
.main-nav__dropdown ul > li > a {
    color: var(--nav-text);
}
.is-open>.main-nav__dropdown {
    display: block;
}
.main-nav__dropdown ul {
    list-style-type: none;
    padding-left: 0;
}
.main-nav__dropdown ul>li {
    padding-left: 0px;
}
.main-nav__dropdown ul>li>a {
    display: inline-block;
    padding: 10px 20px;
}
.main-nav__dropdown ul>li>a:hover,
.main-nav__dropdown ul>li>a:focus,
.main-nav__dropdown ul>li>a.active,
.main-nav__dropdown ul>li.active>a{
    color: var(--color-secondary);
}

.main-nav__dropdown ul>li>a:before {
    position:relative;
    left:-3px;
    top:-2px;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    content: "\E006";
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
}


/*Wide dropdown*/
.navbar-nav-main>.wide-dropdown-wrapper {
    position: static;
}
li.wide-dropdown-wrapper:last-child>.main-nav__dropdown,
.wide-dropdown-wrapper>.main-nav__dropdown {
    right: 0;
    left: 0;
    white-space: normal;
    margin-top: var(--navbar-border-width);
}

@media screen and (min-width: 768px) {
    .main-nav__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 18px 10px;
        white-space: nowrap;
        /*background: #f3f6f8;
        background: linear-gradient(to bottom,  var(--color-info) 0%,rgba(255,255,255,1) 150px);*/
        background: white;
        box-shadow: 0 0 5px rgba(0,0,0,.25);
    }
    ul>li:last-child > .main-nav__dropdown {
        left: auto;
        right: 0;
    }
    .main-nav__dropdown ul>li>a {
        padding: 5px 0;
    }
    .navbar__item:hover>.main-nav__dropdown,
    li:hover>.main-nav__dropdown,
    .navbar__item.is-open > .main-nav__dropdown,
    li.is-open > .main-nav__dropdown {
        display: block;
    }

    .navbar-nav-main>.wide-dropdown-wrapper .nav-block {
        border-left:2px solid #dce0e3;
        margin:10px 0;
        padding-left:20px;
    }
    .main-nav__dropdown ul>li>a.nav-block__title {
        font-family: var(--font-heading-family);
        font-size:24px;
        color:var(--color-body-text);
    }
    .main-nav__dropdown ul>li>a.nav-block__title:before {
        display: none;
    }
    /*hide navigation levels */
    .main-nav__dropdown ul ul ul {
        display: none;
    }
    .main-nav__dropdown .col-sm-12 ul ul {
        display: none;
    }

}

@media screen and (min-width:1200px) {
    .main-nav__dropdown {
        padding: 18px 30px 18px 20px;
    }
}