.footer{
    font-size: 0.9375em;
}
.footer a:hover {
    text-decoration: underline;
}
.footer:before {
    position: relative;
    top: 2px;
    display: block;
    content: '';
    background: url("/static/img/overlays/bg-faecher-blue.png") bottom center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 0;
    padding-top: 8.7%;/*calc(145*100%/1680);*/
}
.footer .text-secondary,
.footer .bg-primary a ,
.footer .bg-primary ul.list-default--secondary>li:before,
.footer .bg-primary .footer__col a.text-secondary:hover {
    color: #fff;
}
.footer .bg-primary,
.footer .bg-primary .footer__col .list-default--secondary a{
    color: #fff;
}
@media screen and (max-width: 767px) {
    .footer{
        font-size: 1.1em;
    }
    .footer .bg-primary .btn {
        color: var(--color-primary);
    }
    .footer__col {
        clear: both;
        float: none;
    }
    .footer__col h3,
    .footer__col .h3 {
        clear: both;
        padding-top: 15px;
    }
    .footer__email {
        width: 200px;
    }
    .footer-partner li {
        float:left;
        width: 33.33333%;
    }
    .footer-partner li:nth-child(4) {
        clear:left;
    }
}
.footer__email:hover .btn-secondary {
    background-color: #566732;
}
@media screen and (min-width: 768px) {
    .footer-wrapper {
        position: relative;
        top: -20px;
    }
}

/*small footer*/
.footer--small {
    color: #fff;
    background: var(--color-primary);
}
@media screen and (min-width: 768px) {
    .footer--small {
        background: url("/static/img/overlays/bg-faecher-blue.png") bottom center no-repeat;
        padding-top: calc(145*100%/1680);
        background-size: 100% auto;
    }
}


