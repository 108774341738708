.embed-responsive .video-js {
    height: 100% !important;
    width: 100% !important;
}
.embed-responsive .video-js .vjs-control,
.embed-responsive .video-js .vjs-time-divider{
    color: white;
}
.embed-responsive .video-js .vjs-progress-holder.vjs-slider,
.embed-responsive .video-js .vjs-volume-bar{
    background: rgba(255, 255, 255, 0.6);
}
.embed-responsive .video-js .vjs-play-progress,
.embed-responsive .video-js .vjs-volume-level{
    background: var(--color-secondary);
}
.vjs-big-play-button  {
    border-radius: 50% !important;
    color: white !important;
    width: 3em !important;
    height: 3em !important;
    background: var(--color-secondary) !important;
    border: .1em solid #fff !important;
}
.embed-responsive .video-js .vjs-volume-handle,
.embed-responsive .video-js .vjs-seek-handle {
    color: #434f29;
}
.embed-responsive .vjs-default-skin .vjs-control:before,
.vjs-default-skin .vjs-slider-handle:before,
.vjs-default-skin .vjs-slider-handle:before {
    text-shadow: none !important;
}
.embed-responsive .vjs-default-skin .vjs-control-bar {
    background-color: rgba(109, 126, 141, 1);
}
.embed-responsive .video-js:hover .vjs-default-skin .vjs-big-play-button {
    background: var(--color-secondary);
}
.vjs-big-play-button:before {
    line-height: 1 !important;
    font-size: 2em !important;
    content: '\E029' !important;
    top: 0.2em !important;
    left: 0.05em !important;
    text-shadow: none !important;
    font-family: iconfont !important;
}
.embed-responsive .vjs-default-skin.vjs-paused .vjs-big-play-button {
    display: block;
}
.embed-responsive .vjs-default-skin .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
@media screen and (max-width: 767px) {
    .vjs-default-skin .vjs-big-play-button {
        font-size: 2em !important;
    }
}