@font-face {
    font-family: "iconfont";
    src: url('/static/debug/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/static/debug/icons/font/iconfont.woff') format('woff'),
    url('/static/debug/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-sessellift_zwei:before { content: "\EA01" }
.icon-angebote:before { content: "\E001" }
.icon-arrow-slider-big_left:before { content: "\E002" }
.icon-arrow-slider-big_right:before { content: "\E003" }
.icon-arrow-slider-small_left:before { content: "\E004" }
.icon-arrow-slider-small_right:before { content: "\E005" }
.icon-arrow:before { content: "\E006" }
.icon-arrow_buchen:before { content: "\E007" }
.icon-arrow_down:before { content: "\E008" }
.icon-babylift:before { content: "\E009" }
.icon-blog:before { content: "\E00A" }
.icon-calendar:before { content: "\E00B" }
.icon-car:before { content: "\E00C" }
.icon-check:before { content: "\E00D" }
.icon-download:before { content: "\E00E" }
.icon-einseil-umlaufbahn:before { content: "\E00F" }
.icon-events:before { content: "\E010" }
.icon-foerderband:before { content: "\E011" }
.icon-fruehling:before { content: "\E012" }
.icon-galerie:before { content: "\E013" }
.icon-gondel:before { content: "\E014" }
.icon-herbst:before { content: "\E015" }
.icon-infos:before { content: "\E016" }
.icon-konsole_anlagen:before { content: "\E017" }
.icon-konsole_share:before { content: "\E018" }
.icon-konsole_sun:before { content: "\E019" }
.icon-konsole_tickets:before { content: "\E01A" }
.icon-konsole_webcam:before { content: "\E01B" }
.icon-liste:before { content: "\E01C" }
.icon-locator:before { content: "\E01D" }
.icon-map-pin:before { content: "\E01E" }
.icon-mashup_blog:before { content: "\E01F" }
.icon-mashup_facebook:before { content: "\E020" }
.icon-mashup_google-plus:before { content: "\E021" }
.icon-mashup_instagram:before { content: "\E022" }
.icon-mashup_youtube:before { content: "\E023" }
.icon-mobile_menue:before { content: "\E024" }
.icon-mobile_menue_close:before { content: "\E025" }
.icon-news:before { content: "\E026" }
.icon-pano:before { content: "\E027" }
.icon-plane:before { content: "\E028" }
.icon-play:before { content: "\E029" }
.icon-schlepper:before { content: "\E02A" }
.icon-search:before { content: "\E02B" }
.icon-sessellift_acht:before { content: "\E02C" }
.icon-sessellift_drei:before { content: "\E02D" }
.icon-sessellift_sechs:before { content: "\E02E" }
.icon-sessellift_vier:before { content: "\E02F" }
.icon-snow:before { content: "\E030" }
.icon-social_blog:before { content: "\E031" }
.icon-social_facebook:before { content: "\E032" }
.icon-social_flickr:before { content: "\E033" }
.icon-social_google-plus:before { content: "\E034" }
.icon-social_instagram:before { content: "\E035" }
.icon-social_yoututbe:before { content: "\E036" }
.icon-sommer:before { content: "\E037" }
.icon-sommercard:before { content: "\E038" }
.icon-star:before { content: "\E039" }
.icon-sun:before { content: "\E03A" }
.icon-tragseilbahn:before { content: "\E03B" }
.icon-poi-pfeil:before { content: "\E03C" }
.icon-vorbereitung:before { content: "\E03D" }
.icon-warenkorb:before { content: "\E03E" }
.icon-webcam:before { content: "\E03F" }
.icon-winter:before { content: "\E040" }
.icon-plus:before { content: "\E041" }
.icon-arrow-right-bold:before { content: "\E042" }
.icon-arrow-right:before { content: "\E043" }
.icon-arrow-down:before { content: "\E044" }
.icon-arrow-left:before { content: "\E045" }
.icon-close:before { content: "\E046" }
.icon-info:before { content: "\E047" }
.icon-minus:before { content: "\E048" }
.icon-pressebilder:before { content: "\E049" }
.icon-pressetexte:before { content: "\E04A" }
.icon-phone:before { content: "\E04B" }
.icon-kalender:before { content: "\E04C" }
.icon-konsole_fotos:before { content: "\E04D" }
.icon-sessellift:before { content: "\E04E" }
.icon-oeffnungszeiten:before { content: "\E04F" }
.icon-tickets:before { content: "\E050" }
.icon-trennelement-dunkel:before { content: "\E051" }
.icon-anlagen:before { content: "\E052" }
.icon-train:before { content: "\E053" }
.icon-hit-der-woche:before { content: "\E054" }
.icon-kulinarik:before { content: "\E055" }
.icon-webshop:before { content: "\E056" }
.icon-trennelement-hell:before { content: "\E057" }
.icon-trennelement:before { content: "\E058" }
.icon-familie:before { content: "\E060" }
.icon-mag:before { content: "\E061" }
.icon-angebote-info-icon:before { content: "\E062" }
.icon-social_twitter:before { content: "\E070" }


        :root {
--icon-sessellift_zwei: "\EA01";
    --icon-angebote: "\E001";
    --icon-arrow-slider-big_left: "\E002";
    --icon-arrow-slider-big_right: "\E003";
    --icon-arrow-slider-small_left: "\E004";
    --icon-arrow-slider-small_right: "\E005";
    --icon-arrow: "\E006";
    --icon-arrow_buchen: "\E007";
    --icon-arrow_down: "\E008";
    --icon-babylift: "\E009";
    --icon-blog: "\E00A";
    --icon-calendar: "\E00B";
    --icon-car: "\E00C";
    --icon-check: "\E00D";
    --icon-download: "\E00E";
    --icon-einseil-umlaufbahn: "\E00F";
    --icon-events: "\E010";
    --icon-foerderband: "\E011";
    --icon-fruehling: "\E012";
    --icon-galerie: "\E013";
    --icon-gondel: "\E014";
    --icon-herbst: "\E015";
    --icon-infos: "\E016";
    --icon-konsole_anlagen: "\E017";
    --icon-konsole_share: "\E018";
    --icon-konsole_sun: "\E019";
    --icon-konsole_tickets: "\E01A";
    --icon-konsole_webcam: "\E01B";
    --icon-liste: "\E01C";
    --icon-locator: "\E01D";
    --icon-map-pin: "\E01E";
    --icon-mashup_blog: "\E01F";
    --icon-mashup_facebook: "\E020";
    --icon-mashup_google-plus: "\E021";
    --icon-mashup_instagram: "\E022";
    --icon-mashup_youtube: "\E023";
    --icon-mobile_menue: "\E024";
    --icon-mobile_menue_close: "\E025";
    --icon-news: "\E026";
    --icon-pano: "\E027";
    --icon-plane: "\E028";
    --icon-play: "\E029";
    --icon-schlepper: "\E02A";
    --icon-search: "\E02B";
    --icon-sessellift_acht: "\E02C";
    --icon-sessellift_drei: "\E02D";
    --icon-sessellift_sechs: "\E02E";
    --icon-sessellift_vier: "\E02F";
    --icon-snow: "\E030";
    --icon-social_blog: "\E031";
    --icon-social_facebook: "\E032";
    --icon-social_flickr: "\E033";
    --icon-social_google-plus: "\E034";
    --icon-social_instagram: "\E035";
    --icon-social_yoututbe: "\E036";
    --icon-sommer: "\E037";
    --icon-sommercard: "\E038";
    --icon-star: "\E039";
    --icon-sun: "\E03A";
    --icon-tragseilbahn: "\E03B";
    --icon-poi-pfeil: "\E03C";
    --icon-vorbereitung: "\E03D";
    --icon-warenkorb: "\E03E";
    --icon-webcam: "\E03F";
    --icon-winter: "\E040";
    --icon-plus: "\E041";
    --icon-arrow-right-bold: "\E042";
    --icon-arrow-right: "\E043";
    --icon-arrow-down: "\E044";
    --icon-arrow-left: "\E045";
    --icon-close: "\E046";
    --icon-info: "\E047";
    --icon-minus: "\E048";
    --icon-pressebilder: "\E049";
    --icon-pressetexte: "\E04A";
    --icon-phone: "\E04B";
    --icon-kalender: "\E04C";
    --icon-konsole_fotos: "\E04D";
    --icon-sessellift: "\E04E";
    --icon-oeffnungszeiten: "\E04F";
    --icon-tickets: "\E050";
    --icon-trennelement-dunkel: "\E051";
    --icon-anlagen: "\E052";
    --icon-train: "\E053";
    --icon-hit-der-woche: "\E054";
    --icon-kulinarik: "\E055";
    --icon-webshop: "\E056";
    --icon-trennelement-hell: "\E057";
    --icon-trennelement: "\E058";
    --icon-familie: "\E060";
    --icon-mag: "\E061";
    --icon-angebote-info-icon: "\E062";
    --icon-social_twitter: "\E070";
    
}