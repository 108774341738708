.slide.panorama-slide {
    padding-top: 30px;
    padding-bottom: 0;
    position: relative;
}
.panorama-slide__text {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
}
.panorama-slide__btn {
    border-radius: 0;
    margin-bottom: 5px;
    padding: 6px 60px;
    font-size: 15px;
    width: 280px;
}
.panorama-slide__text {
    color: white;
    font-size: 20px;
}
@media screen and (min-width: 768px){
    .slide.panorama-slide {
        padding-top: 80px;
    }
    .embed-responsive.embed-responsive-panorama {
        padding-top: 49.82142857142857%;
    }
    .panorama-slide .text-right-lg {
        text-align: right;
    }
}
@media screen and (min-width: 1200px){
    .slide.panorama-slide {
        padding-top: 60px;
    }
}
@media screen and (max-width: 767px){
    .panorama-slide .embed-responsive-panorama {
        padding: 0;
        height: auto;
    }
}

@media screen and (min-width: 768px){
    .panorama-slide__text {
        position: absolute;
        top: 15px;
    }
}
@media screen and (min-width: 1200px){
    .panorama-slide__text {
        top: 30px;
    }
}