:root {
    --teaser-hover-btn-color: var(--color-secondary);
    --teaser-btn-color: var(--color-primary);
    --teaser-box-shadow: var(--color-primary-comp);
}
.teaser {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: var(--color-info);
    margin-bottom: 20px;
    width: 100%;
    /*max-width: 280px;*/
}
.teaser__body {
    position: relative;
    /*min-height: 80px;*/
    padding: 15px 15px;
    background: var(--color-info);
    color: var(--color-dark-grey);
}


/* same height teaser stuff */
.row--same-height .teaser__body--grow {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}


@media screen and (min-width: 768px) {
    .teaser {
        max-width: none;
    }
    .teaser__body {
        /*min-height: 130px;*/
    }
}

.teaser__body strong{
    display: block;
    text-transform: uppercase;
}
.teaser__btn {
    float:right;
    height: 27px;
    width: 27px;
    border-radius: 100%;
    background-color: var(--teaser-btn-color);
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 1;
    transition: 200ms ease;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 3px;
    display: block;
}

/* same height teaser btn
.row--same-height .teaser__btn {
    float:none;
    clear:both;
    position: absolute;
    right:10px;
    top:10px;
}*/
.teaser__btn--bottom-right {
    margin: 0 10px 17px 0;
}
.teaser__btn>.icon {
    line-height: 27px;
}
.teaser__text {
    display: block;
    font-size: 14px;
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}
a.teaser__text.teaser__text {
    color: inherit;
}
.teaser__badge {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: var(--color-primary-comp);
    border: 2px solid #fff;
    border-radius: 100%;
    width: 75px;
    height: 75px;
    text-align: center;
    padding-top: 14px;
    line-height: 1.2;
    box-shadow: 0 0 10px rgba(0,0,0,.25);
    background-color: var(--color-primary);
    will-change: transform;
    transform: translateZ(0);
    transition: transform 150ms ease;
}
.teaser__badge__icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -30px;
    font-size: 60px;
    text-align: center;
}
@media screen and (min-width: 1200px) {
    .teaser__badge {
        width: 92px;
        height: 92px;
        bottom: 20px;
        right: 20px;
        padding-top: 18px;
    }
    .teaser__badge__icon {
        margin-top: -37px;
        font-size: 75px;
    }
}

/*todo*/
.teaser__badge--middle {
    top: 50%;
    left: 50%;
    margin-top: -37px;
    margin-left: -37px;
}

/*Hover*/
.teaser:hover .teaser__btn {
    color: #fff;
    background-color: var(--teaser-hover-btn-color);
    box-shadow: 0 0 0 5px var(--teaser-box-shadow);
}
.teaser--highlight:hover .teaser__btn {
    box-shadow: none;
    color: #fff;
    background-color: var(--color-primary);
}
.teaser:hover .teaser__badge {
    transform: scale(1.04) rotate(10deg) translateZ(0);
}
.teaser:hover .teaser__text {
    color: inherit;
}



/*teaser--highlight*/
.teaser--highlight a {
     color:#fff;
 }

.teaser--highlight--blue a {
    color:#fff;
}


/*Big*/
@media screen and (min-width: 768px) {
    .teaser--big>.teaser__body {
       /*min-height: 130px;*/
    }
}

/*Small*/
@media screen and (min-width: 768px) {
    .teaser--small>.teaser__body {
        min-height:70px;
        padding: 10px 15px;
    }
}


/*Highlight*/
.teaser--highlight,
.teaser--highlight .teaser__body,
.teaser--higlight-on-hover:hover .teaser__body{
    background-color: var(--color-secondary);
    color: var(--color-secondary-comp);
}
.teaser--highlight .teaser__btn {
    -webkit-background-origin: content-box;
    background-color: #fff;
    color: var(--color-secondary);
}
.teaser--highlight .text-primary {
    color: var(--color-primary-comp);
}

/*Highlight Teaser Blau*/
.teaser--highlight--blue,
.teaser--highlight--blue .teaser__body,
.teaser--higlight--blue:hover .teaser__body{
    background-color: var(--color-primary);
    color: var(--color-primary-comp);
}
.teaser--highlight--blue .teaser__btn {
    -webkit-background-origin: content-box;
    background-color: #fff;
    color: var(--color-primary);
}
.teaser--highlight--blue .text-primary {
    color: var(--color-secondary-comp);
}

/*Wide*/
.teaser--wide {
    max-width: 580px;
}

/*No bg*/
.teaser--no-bg {
    background: transparent;
}

/*Horizontal*/
.teaser--horizontal {
    max-width: none;
}
.teaser--horizontal .teaser__body {
    padding: 10px 10px 10px 0;
}

@media screen and (min-width: 1200px) {
    .teaser__text {
        font-size: 17px;
    }

    .teaser__btn {
        height: 37px;
        width: 37px;
        font-size: 22px;
        margin-top: 5px;
        margin-left: 5px;
    }
    .teaser__btn>.icon {
        line-height: 36px;
    }

    /*small*/
    .teaser--small .teaser__text {
        font-size: 16px;
    }
}

.teaser__btn .icon-plus {
    vertical-align: middle;
}
.pull-right.floated-item .teaser {
    width: 244px;
}


.banner-container {
    position: relative;
    width: 160px;
    height: 600px;
}
.banner-container #nab_side_ad_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 160px;
    height: 600px;
}