.footer-sprite {
    background-image: url(/static/img/sprite/footer-sprite.png);
    background-repeat: no-repeat;
    display: block;
}

.footer-sprite-oesterreich {
    width: 205px;
    height: 50px;
    background-position: -10px -10px;
}

.footer-sprite-site-by-elements {
    width: 147px;
    height: 15px;
    float:right;
    background-position: -10px -80px;
}

.footer-sprite-ski-amade {
    width: 237px;
    height: 50px;
    background-position: -10px -115px;
}

.footer-sprite-stt {
    width: 73px;
    height: 50px;
    background-position: -235px -10px;
}

@media screen and (max-width: 767px) {
    /*.footer-sprite {*/
        /*background-size: 159px 87.5px;*/
    /*}*/
    .footer-sprite-oesterreich {
        width: 102.5px;
        height: 25px;
        background-position: -5px -5px;
    }

    .footer-sprite-site-by-elements {
        float:none;
        clear: both;
        margin:10px auto;
    }
    /*.footer-sprite-site-by-elements {*/
        /*width: 73.5px;*/
        /*height: 7.5px;*/
        /*background-position: -5px -40px;*/
    /*}*/
    .footer-sprite-ski-amade {
        width: 118.5px;
        height: 25px;
        background-position: -5px -57.5px;
    }
    .footer-sprite-stt {
        width: 36.5px;
        height: 25px;
        background-position: -117.5px -5px;
    }
}

@media screen and (min-width: 768px) and (max-width:1400px) {
    .footer-sprite-site-by-elements {
        margin-right:120px;
    }
}