.slick-dots {
    padding-left: 0;
    list-style-type: none;
    margin: 0;
}
.slick-dots > li {
    display: inline-block;
}
.slick-dots > li + li {
    margin-left: 7px;
}
.slick-dots button {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin:0;
    padding:0;
    -webkit-appearance: none;
    background: #ffffff;
    text-indent: -999em;
    overflow: hidden;
    border-radius: 50%;
    border: none;
}
.slick-dots button:hover,
.slick-dots .slick-active button {
    background: var(--color-secondary);
}

.slick-btn--default {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    width: 30px;
    height: 60px;
    line-height: 50px;
    font-size: 40px;
    border-radius: 0 80px 80px 0;
    background: #e6007e;
    color: #fff;
    border: 2px solid;
    border-left: 0;
    text-align: center;
    padding: 1px 6px;
}
@media screen and (min-width: 768px) {
    .slick-btn--default {
        width: 40px;
        height: 80px;
        line-height: 65px;
        font-size: 50px;
    }
}
.slick-btn--default.slick-btn--next {
    left: auto;
    right: 0;
    border-radius: 80px 0 0 80px;
    border: 2px solid;
    border-right: 0;
}
.slick-btn--default:hover,
.slick-btn--default:focus {
    background: var(--color-secondary-darkened);
}

.slick-btn--default > .icon {
    vertical-align: middle;
    position: relative;
    top: -4px;
}
@media screen and (min-width: 768px) {
    .slick-btn--default > .icon {
        top: -6px;
    }
}
.slick-btn--default.slick-btn--prev > .icon {
    left: -16px;
}
.slick-btn--default.slick-btn--next > .icon {
    left: -5px;
}



/*overlay buttons*/
.slick-btn--overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    color: #ffffff;
    background: none;
    border: none;
    padding: 0;
    text-align: center;
}
.slick-btn--overlay:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--nav-text);
    opacity: .6;
}
.slick-btn--overlay:hover:before,
.slick-btn--overlay:focus:before {
    opacity: .8;
}
.slick-btn--overlay > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
}
.slick-btn--overlay.slick-btn--next {
    left: auto;
    right: 0;
}