.horizontal-list-group {
    font-size: 0;
    list-style-type: none;
    padding: 0;
}
.horizontal-list-group__item {
    display: inline-block;
    font-size: 1rem;
    margin-right: 1px;
    line-height: 1;
    padding: 10px 20px;
}
.horizontal-list-group__item:first-child {
    border-radius: 4px 0 0 4px;
}
.horizontal-list-group__item:last-child {
    border-radius: 0 4px 4px 0;
}