:root {
    --text-transform-heading: none;
    --link-hover-color: var(--color-secondary-darkened);
}
.wysiwyg {
    /*Needed For responsive tables*/
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.wysiwyg a {
    color: var(--color-secondary);
    font-weight:bold;
}
.wysiwyg a:hover {
    color: var(--link-hover-color);
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6,
.wysiwyg .h1,
.wysiwyg .h2,
.wysiwyg .h3,
.wysiwyg .h4,
.wysiwyg .h5,
.wysiwyg .h6 {
    color: var(--color-primary);
    margin-top: 20px;
    font-family: var(--font-heading-family);
    text-transform: var(--text-transform-heading);
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg .h1,
.wysiwyg .h2 {
    text-transform: var(--text-transform-heading);
}
.wysiwyg h6,
.wysiwyg .h6 {
    margin-top: 1em;
    margin-bottom: 0;
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}
.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child,
.wysiwyg .h1:first-child,
.wysiwyg .h2:first-child,
.wysiwyg .h3:first-child,
.wysiwyg .h4:first-child,
.wysiwyg .h5:first-child,
.wysiwyg .h6:first-child {
    margin-top: 0;
}

.list-arrows,
.list-arrows ul,
.list-default {
    padding-left: 0;
}
.wysiwyg ul,
.wysiwyg ol {
    padding-left: 30px;
}
ul.list-default,
.list-arrows ul,
.list-arrows,
.wysiwyg ul {
    list-style-type: none;
}
ul.list-default>li,
.list-arrows li,
.wysiwyg ul>li {
    text-indent: -0.37em;
}
.wysiwyg li {
    margin-bottom: 7px;
}
ul.list-default.columns-2>li {
    text-indent: 0;
}
ul.list-default>li:before,
.wysiwyg ul>li:before {
    content: "•";
    display: inline-block;
    margin-right: 5px;
    color: var(--color-primary);
}
.list-arrows li:before {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: "\E006";
}

.wysiwyg img {
    max-width: 100%;
    height: auto;
}

/* For table in wysiwyg see table.css*/
