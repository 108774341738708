:root {
    --gallery__caption-color--webcam: var(--color-secondary);
}
.gallery__caption {
    padding-top: 10px;
}
.gallery__caption>.pull-left {
    margin-left: 15px;
    margin-bottom: 10px;
}
.gallery__caption.webcam--caption {
    color: var(--gallery__caption-color--webcam);
}
.gallery__main> .gallery__item + .gallery__item {
    /*only show first item before carousel is initialized*/
    display: none;
}

.gallery__main .gallery__item .gallery__icon{
    position: relative;
    z-index: 1;
    outline: none;
    display: none;
}

.gallery__icon span{
    float: right;
    font-size: 20px;
    padding: 12px;
    background-color: var(--nav-text);
    opacity: 0.55;
    color: #fff;
}
.gallery__icon span:hover{
    opacity: 0.88;
}

.gallery__main {
    position: relative;
}

/*Thumbs*/
.gallery__thumbs {
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}
.gallery__thumbs .slide-item {
    width: 90px;
    padding-left: 6px;
    padding-right: 6px;
    cursor: pointer;
}
@media screen and (min-width: 768px) {
    .gallery__thumbs .slide-item {
        width: 135px;
    }
    .gallery__main .gallery__item .gallery__icon{
        display: block;
    }
}
.gallery__thumbs .slick-current .embed-responsive:after,
.gallery__thumbs .slick-slide:hover .embed-responsive:after,
.gallery__thumbs .slick-slide:focus .embed-responsive:after {
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    display: inline-block;
    background: rgba(0,0,0,0.35);
}