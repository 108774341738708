a {
    color: inherit;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: inherit;
}
/*ul {*/
    /*padding-left: 0;*/
/*}*/
mark {
    background: transparent;
    color: inherit;
}
hr {
    border-color: #b0b7bf;
}
legend {
    border: none;
}