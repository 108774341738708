:root {
    --meta-nav-bg: var(--color-primary);
}
@media screen and (min-width: 768px){
    .page-wrapper {
        padding-top: calc(var(--navbar-height) + 50);
    }
    .no-header-bar .page-wrapper {
        padding-top: var(--navbar-height);
    }
    .navbar-main {
        top: 50px;
    }
    .navbar-main.affix,
    .no-header-bar .navbar-main {
        top: 0;
    }
    .meta-navigation {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 101; /*has to be higher then main nav bar*/
    }
    .meta-navigation {
        height: 50px;
        padding: 15px 0;
        background: var(--meta-nav-bg);
        color: #fff;
        font-size: 14px;
    }
    .meta-navigation .dropdown__toggle {
        cursor: pointer;
    }
    .meta-navigation .dropdown__toggle .icon {
        vertical-align: middle;
        font-size: 12px;
    }
    .meta-navigation__link {
        font-style: italic;
    }
    .meta-navigation .dropdown-menu {
        color: #262626;
        padding: 0;
        border-radius: 0;
        background: linear-gradient(to bottom,#f2f2f2 0,#fff 150px);
    }
    .meta-navigation .dropdown-menu li {
        text-align: center;
    }
    .meta-navigation .dropdown-menu li a {
        padding: 5px 20px;
    }
    .meta-navigation .dropdown-menu>li>a:hover, .meta-navigation .dropdown-menu>li>a:focus {
        color: var(--color-primary);
        text-decoration: none;
        background-color: transparent;
    }
    .meta-navigation .icon-phone {
        margin-right: 5px;
        vertical-align: sub;
        font-size: 20px;
    }
}