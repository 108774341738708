.map-slide__tab-controls {
    margin-bottom: 15px;
    text-align: right;
    /*font-size: 25px;*/
}
.map-slide__tab-controls>.btn {
    line-height: 1;
    padding-left: 17px;
    padding-right: 17px;
}

.map-slide__pane {
    position: relative;
}
.map-slide__poi {
    position: absolute;
    width: 80px;
}
.map-slide__poi-top {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5.5px 10px 5.5px;
    border-color: transparent transparent var(--color-secondary) transparent;
    position: absolute;
    top: -10px;
    left: 35px;
}
.map-slide__poi-bottom {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5.5px 10px 5.5px;
    border-color: transparent transparent var(--color-secondary) transparent;
    position: absolute;
    bottom: -5px;
    left: 35px;
}
.map-slide__poi a:hover {
    color: var(--color-primary);
}
.map-slide__poi a:hover .map-slide__poi-bottom, .map-slide__poi a:hover .map-slide__poi-top {
    border-color: transparent transparent var(--color-primary) transparent;
}
@media screen and (min-width: 768px) {
    .map-slide__description {
        position: absolute;
        top: 0;
        left: 0;
    }
    .map-slide__tab-controls {
        position: relative;
        z-index: 1;
        margin-bottom: 15px;
        float: right;
    }

}