.scroll-top-btn {
    position: fixed;
    bottom: 8px;
    right: 8px;
    padding:6px 40px 6px 12px;
    color:#fff;
    background-color: var(--color-primary);
    transform: translateZ(0);
    opacity: 0;
    transition: opacity 120ms ease;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    z-index: 1000;
}
.scroll-top-btn:hover,
.scroll-top-btn:focus{
    color:#fff;
}
.scroll-top-btn:after {
    content:'';
    display: inline-block;
    position: absolute;
    top:14px;
    right:14px;
    width:12px;
    height:12px;
    border:3px solid #fff;
    border-width:0 3px 3px 0;
    display: inline-block;
    transform: rotate(225deg);
}
.scroll-top-btn.is-visible {
    opacity: 1;
}

@media screen and (max-width: 767px) {
    .scroll-top-btn {
        position: fixed;
        bottom: 8px;
        right: 8px;
        padding:0;
        width:38px;
        height:38px;
    }
    .scroll-top-btn>span {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .scroll-top-btn {
        bottom: 17px;
        right: 15px;
    }
}