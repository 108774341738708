.content-block .breadcrumbs-info {
    font-size: 13px;
}
.breadcrumb {
    display: inline-block;
    background: inherit;
    margin-bottom: 0;
    font-size: 13px;
}
.breadcrumb>li>a {
    color: var(--color-secondary);
    text-decoration: underline;
}
.breadcrumb>li>a:hover {
    color: var(--color-primary);
}
.breadcrumb>.active>a {
    color: inherit;
    text-decoration: none;
}
.breadcrumb.breadcrumb>li:before {
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\E006";
    vertical-align: middle;
    color: #cad1d9;
}
.breadcrumb>.active {
    color: var(--color-body-text);
}