.remaining-screen-container {
    position: fixed;
    top: 63px;
    left: 0;
    right: 0;
    bottom:0;
}
@media screen and (min-width: 768px) {
    .remaining-screen-container {
        top: calc(var(--navbar-height-smaller-screens) + 50px); /*50 = meta bar height*/
    }
}
@media screen and (min-width: 1200px) {
    .remaining-screen-container {
        top: calc(var(--navbar-height) + 50px); /*50 = meta bar height*/
    }
}
.remaining-screen-container__content {
    height: 100%;
    width: 100%;
}